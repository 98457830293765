import React from "react";
import Marquee from "react-fast-marquee";
import { StaticImage } from "gatsby-plugin-image";

function CustomMarquee() {
  return (
    <>
      <Marquee
        pauseOnHover={true}
        direction="right"
        gradient={false}
        speed={200}
      >
        <li>
          <div className="partnerImg">
            <StaticImage placeholder="none" alt="partner" src="../images/partners/partners-1.svg" />
          </div>
        </li>
        <li>
          <div className="partnerImg">
            <StaticImage placeholder="none" alt="partner" src="../images/partners/partners-2.svg" />
          </div>
        </li>
        <li>
          <div className="partnerImg bigDataIdea">
            <StaticImage placeholder="none" alt="partner" src="../images/partners/partners-3.svg" />
          </div>
        </li>
        <li>
          <div className="partnerImg">
            <StaticImage placeholder="none" alt="partner" src="../images/partners/partners-4.svg" />
          </div>
        </li>
        <li>
          <div className="partnerImg">
            <StaticImage placeholder="none" alt="partner" src="../images/partners/partners-5.svg" />
          </div>
        </li>
      </Marquee>
    </>
  );
}

export default CustomMarquee;
