import React, { Component } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class ServiceSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.slide = this.slide.bind(this);
  }
  slide(y) {
    y > 0 ? this.slider?.slickNext() : this.slider?.slickPrev();
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
      ],
    };
    return (
      <div className="ourservice-boxes">
        <Slider {...settings} ref={(slider) => (this.slider = slider)}>
          <div className="card serviceItem">
            <Link to="/services/enterprise-solutions">
              <div className="card-img">
                <StaticImage placeholder="none"
                  className="cardgradient"
                  alt="solutions"
                  src="../images/ourservice/enterprise-solutions.svg"
                />
                <StaticImage placeholder="none"
                  className="mobile-cardgradient"
                  alt="solutions"
                  src="../images/ourservice/mobile-enterprise-solutions.svg"
                />
              </div>
              <div className="card-body serviceBody">
                <div className="card-top">
                  <span className="serviceCount">01</span>
                  <div className="viewMore">
                    View more
                    <span className="circle">
                      <StaticImage placeholder="none" alt="arrow" src="../images/arrow.svg" />
                    </span>
                  </div>
                </div>
                <div className="card-bottom">
                  <h2>Enterprise Solutions</h2>
                  <ul className="newsCaption-link">
                    <li>Enterprise application development </li>
                    <li>Microservice architecture</li>
                    <li>Enterprise automation</li>
                    <li>Big Data Service & Predictive Analytics</li>
                    <li>Blockchain</li>
                    <li>Ecommerce</li>
                  </ul>
                </div>
              </div>
              <div className="img-bottom">
                <StaticImage placeholder="none" alt="arrow" src="../images/ellipse.png" />
              </div>
            </Link>
          </div>

          <div className="card serviceItem">
            <Link to="/services/mobility-solutions">
              <div className="card-img">
                <StaticImage placeholder="none"
                  className="cardgradient"
                  alt="solutions"
                  src="../images/ourservice/mobility-solutions.svg"
                />
                <StaticImage placeholder="none"
                  className="mobile-cardgradient"
                  alt="solutions"
                  src="../images/ourservice/mobile-mobility-solutions.svg"
                />
              </div>
              <div className="card-body serviceBody">
                <div className="card-top">
                  <span className="serviceCount">02</span>
                  <div className="viewMore">
                    View more
                    <span className="circle">
                      <StaticImage placeholder="none" alt="arrow" src="../images/arrow.svg" />
                    </span>
                  </div>
                </div>
                <div className="card-bottom">
                  <h2>Mobility Solutions</h2>
                  <ul className="newsCaption-link">
                    <li>iOS</li>
                    <li>Android</li>
                    <li>Cross platform</li>
                    <li>Progressive Web Apps</li>
                  </ul>
                </div>
              </div>
              <div className="img-bottom">
                <StaticImage placeholder="none" alt="arrow" src="../images/ellipse.png" />
              </div>
            </Link>
          </div>

          <div className="card serviceItem">
            <Link to="/services/data-solutions">
              <div className="card-img">
                <StaticImage placeholder="none"
                  className="cardgradient"
                  alt="solutions"
                  src="../images/ourservice/data-solutions.svg"
                />
                <StaticImage placeholder="none"
                  className="mobile-cardgradient"
                  alt="solutionsa"
                  src="../images/ourservice/mobile-data-solutions.svg"
                />
              </div>
              <div className="card-body serviceBody">
                <div className="card-top">
                  <span className="serviceCount">03</span>
                  <div className="viewMore">
                    View more
                    <span className="circle">
                      <StaticImage placeholder="none" alt="arrow" src="../images/arrow.svg" />
                    </span>
                  </div>
                </div>
                <div className="card-bottom">
                  <h2>Data Solutions</h2>
                  <ul className="newsCaption-link">
                    <li>Data science</li>
                    <li>Data engineering</li>
                    <li>Machine learning</li>
                    <li>Artificial intelligence</li>
                  </ul>
                </div>
              </div>
              <div className="img-bottom">
                <StaticImage placeholder="none" alt="arrow" src="../images/ellipse.png" />
              </div>
            </Link>
          </div>

          <div className="card serviceItem">
            <Link to="/services/cloud-solutions">
              <div className="card-img">
                <StaticImage placeholder="none"
                  className="cardgradient"
                  alt="solutions"
                  src="../images/ourservice/cloud-solutions.svg"
                />
                <StaticImage placeholder="none"
                  className="mobile-cardgradient"
                  alt="solutions"
                  src="../images/ourservice/mobile-cloud-solutions.svg"
                />
              </div>
              <div className="card-body serviceBody">
                <div className="card-top">
                  <span className="serviceCount">04</span>
                  <div className="viewMore">
                    View more
                    <span className="circle">
                      <StaticImage placeholder="none" alt="arrow" src="../images/arrow.svg" />
                    </span>
                  </div>
                </div>
                <div className="card-bottom">
                  <h2>Cloud Solutions</h2>
                  <ul className="newsCaption-link">
                    <li>AWS</li>
                    <li>Devops</li>
                    <li>DevSecOps</li>
                    <li>Cloud Services</li>
                    <li>Cloud monitoring</li>
                    <li>Cloud migration</li>
                  </ul>
                </div>
              </div>
              <div className="img-bottom">
                <StaticImage placeholder="none" alt="arrow" src="../images/ellipse.png" />
              </div>
            </Link>
          </div>

          <div className="card serviceItem">
            <Link to="/services/qa-testing">
              <div className="card-img">
                <StaticImage placeholder="none"
                  className="cardgradient"
                  alt="solutions"
                  src="../images/ourservice/qa-solutions.svg"
                />
                <StaticImage placeholder="none"
                  className="mobile-cardgradient"
                  alt="solutions"
                  src="../images/ourservice/mobile-qa-solutions.svg"
                />
              </div>
              <div className="card-body serviceBody">
                <div className="card-top">
                  <span className="serviceCount">05</span>
                  <div className="viewMore">
                    View more
                    <span className="circle">
                      <StaticImage placeholder="none" alt="arrow" src="../images/arrow.svg" />
                    </span>
                  </div>
                </div>
                <div className="card-bottom">
                  <h2>QA & Testing</h2>
                  <ul className="newsCaption-link">
                    <li>Manual</li>
                    <li>Automation</li>
                  </ul>
                </div>
              </div>
              <div className="img-bottom">
                <StaticImage placeholder="none" alt="solutions" src="../images/ellipse.png" />
              </div>
            </Link>
          </div>
        </Slider>
      </div>
    );
  }
}
